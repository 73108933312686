import { ColorValue } from "@ui/types/enums/ColorValue";

type Props = {
  colorValue: ColorValue;
};

export function MailIcon({ colorValue }: Props): JSX.Element {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0.333374H1.66667C0.75 0.333374 0.00833333 1.08337 0.00833333 2.00004L0 12C0 12.9167 0.75 13.6667 1.66667 13.6667H15C15.9167 13.6667 16.6667 12.9167 16.6667 12V2.00004C16.6667 1.08337 15.9167 0.333374 15 0.333374ZM15 3.66671L8.33333 7.83337L1.66667 3.66671V2.00004L8.33333 6.16671L15 2.00004V3.66671Z"
        fill={colorValue}
      />
    </svg>
  );
}
