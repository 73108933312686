/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

export enum BackgroundColorClass {
  BackgroundOverlay = "backgroundColorBackgroundOverlay",
  Error = "backgroundColorError",
  ErrorDisabled = "backgroundColorErrorDisabled",
  ErrorHover = "backgroundColorErrorHover",
  Facebook = "backgroundColorFacebook",
  Ghost = "backgroundColorGhost",
  Google = "backgroundColorGoogle",
  Hard = "backgroundColorHard",
  HardHover = "backgroundColorHardHover",
  Highlight = "backgroundColorHighlight",
  HighlightDisabled = "backgroundColorHighlightDisabled",
  HighlightHover = "backgroundColorHighlightHover",
  LightPurple = "backgroundColorLightPurple",
  LightPurple2 = "backgroundColorLightPurple2",
  LightPurpleHover = "backgroundColorLightPurpleHover",
  Mauve = "backgroundColorMauve",
  MauveHover = "backgroundColorMauveHover",
  MenuPopup = "backgroundColorMenuPopup",
  NotReviewed = "backgroundColorNotReviewed",
  Primary = "backgroundColorPrimary",
  ProgressBar = "backgroundColorProgressBar",
  SearchHighlight = "backgroundColorSearchHighlight",
  Secondary = "backgroundColorSecondary",
  Separator = "backgroundColorSeparator",
  SeparatorDisabled = "backgroundColorSeparatorDisabled",
  SeparatorHover = "backgroundColorSeparatorHover",
  SeparatorWithShadow = "backgroundColorSeparatorWithShadow",
  Success = "backgroundColorSuccess",
  SuccessDisabled = "backgroundColorSuccessDisabled",
  SuccessHover = "backgroundColorSuccessHover",
  TagBackground = "backgroundColorTagBackground",
  Tertiary = "backgroundColorTertiary",
  WebsiteBackground = "backgroundColorWebsiteBackground",
  White = "backgroundColorWhite",
}
