import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react";
import { GlobalClass } from "@ui/types/enums/GlobalClass";
import { deleteProperties } from "shared";
import { joinClasses } from "@ui/utils/joinClasses";

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  hideText?: boolean;
  logEvent?: () => void;
  transparentBg?: boolean;
};

export const PlainButton = forwardRef<HTMLButtonElement, Props>(
  function PlainButtonInner(props: Props, ref) {
    return (
      // eslint-disable-next-line react/button-has-type
      <button
        // Delete some properties so we don't get noisy errors in the browser console
        {...deleteProperties(props, ["hideText", "transparentBg"])}
        className={joinClasses(
          GlobalClass.ButtonPlain,
          props.className,
          props.hideText === true ? GlobalClass.HideText : null
        )}
        onClick={(e) => {
          if (props.onClick != null) {
            props.onClick(e);
          }

          if (props.logEvent != null) {
            props.logEvent();
          }
        }}
        ref={ref}
        style={{
          ...(props.transparentBg == null || props.transparentBg
            ? { backgroundColor: "transparent" }
            : {}),
          ...props.style,
        }}
      >
        {props.children}
      </button>
    );
  }
);
