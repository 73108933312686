import { FlexBox } from "@ui/components/layout/FlexBox";

type Props = {
  icon: JSX.Element;
  label: JSX.Element;
};

export function IconAndLabel({ icon, label }: Props) {
  return (
    <FlexBox alignItems="center" gap={12}>
      {icon}
      {label}
    </FlexBox>
  );
}
