import styles from "@ui/components/buttons/css/ToggleButton.module.css";

type Props = {
  enabled: boolean;
  onClick: () => void;
};

export function ToggleButton({ enabled, onClick }: Props) {
  return (
    <label className={styles.switch}>
      <input
        checked={enabled}
        className={styles.switchInput}
        onChange={onClick}
        type="checkbox"
      />
      <span className={styles.slider} />
    </label>
  );
}
