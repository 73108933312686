import { AspectRatioContainer } from "@ui/components/containers/AspectRatioContainer";

type Props = {
  children: any;
  className?: string;
};

export function SquareContainer({ children, className }: Props): JSX.Element {
  return (
    <AspectRatioContainer className={className} height={1} width={1}>
      {children}
    </AspectRatioContainer>
  );
}
