export enum MediaType {
  AnkiExports = "application/zip",
  ImageGif = "image/gif",
  ImageJpeg = "image/jpeg",
  ImageJpg = "image/jpg",
  ImagePng = "image/png",
  ModelGltfBinary = "model/gltf-binary",
  TextCsv = "text/csv",
  VideoMp4 = "video/mp4",
  VideoQuicktime = "video/quicktime",
}
