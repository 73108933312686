/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

export enum ColorClass {
  BackgroundOverlay = "colorBackgroundOverlay",
  Error = "colorError",
  ErrorDisabled = "colorErrorDisabled",
  ErrorHover = "colorErrorHover",
  Facebook = "colorFacebook",
  Ghost = "colorGhost",
  Google = "colorGoogle",
  Hard = "colorHard",
  HardHover = "colorHardHover",
  Highlight = "colorHighlight",
  HighlightDisabled = "colorHighlightDisabled",
  HighlightHover = "colorHighlightHover",
  LightPurple = "colorLightPurple",
  LightPurple2 = "colorLightPurple2",
  LightPurpleHover = "colorLightPurpleHover",
  Mauve = "colorMauve",
  MauveHover = "colorMauveHover",
  MenuPopup = "colorMenuPopup",
  NotReviewed = "colorNotReviewed",
  Primary = "colorPrimary",
  ProgressBar = "colorProgressBar",
  SearchHighlight = "colorSearchHighlight",
  Secondary = "colorSecondary",
  Separator = "colorSeparator",
  SeparatorDisabled = "colorSeparatorDisabled",
  SeparatorHover = "colorSeparatorHover",
  SeparatorWithShadow = "colorSeparatorWithShadow",
  Success = "colorSuccess",
  SuccessDisabled = "colorSuccessDisabled",
  SuccessHover = "colorSuccessHover",
  TagBackground = "colorTagBackground",
  Tertiary = "colorTertiary",
  WebsiteBackground = "colorWebsiteBackground",
  White = "colorWhite",
}
