import { ColorValue } from "@ui/types/enums/ColorValue";

type Props = {
  colorValue: ColorValue;
};

export function KeyIcon({ colorValue }: Props): JSX.Element {
  return (
    <svg
      width="20"
      height="10"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5417 3.33333C9.85837 1.39167 8.00837 0 5.83337 0C3.07504 0 0.833374 2.24167 0.833374 5C0.833374 7.75833 3.07504 10 5.83337 10C8.00837 10 9.85837 8.60833 10.5417 6.66667H14.1667V10H17.5V6.66667H19.1667V3.33333H10.5417ZM5.83337 6.66667C4.91671 6.66667 4.16671 5.91667 4.16671 5C4.16671 4.08333 4.91671 3.33333 5.83337 3.33333C6.75004 3.33333 7.50004 4.08333 7.50004 5C7.50004 5.91667 6.75004 6.66667 5.83337 6.66667Z"
        fill={colorValue}
      />
    </svg>
  );
}
