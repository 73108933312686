import { Maybe } from "shared";

export function iconSize(iconName: string): Maybe<number> {
  const match = iconName.match(/\d+/);
  if (match != null) {
    return Number(match[0]);
  }

  return null;
}
