import { notify } from "@ui/components/toast/notify";
import { UNEXPECTED_ERROR_MESSAGE } from "@ui/constants/UnexpectedErrorMessage";

export function notifyError(
  description?: string,
  message?: string,
  duration?: number
) {
  notify({
    description: description ?? UNEXPECTED_ERROR_MESSAGE,
    duration,
    message: message ?? UNEXPECTED_ERROR_MESSAGE,
    type: "error",
  });
}
