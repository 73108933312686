import { BodyText } from "@ui/components/text/BodyText";
import { FontClass } from "@ui/types/enums/FontClass";
import type { BodyTextProps as Props } from "@ui/components/text/BodyText";

export function FieldTitle(props: Omit<Props, "fontClass">) {
  return (
    <BodyText
      {...props}
      fontClass={FontClass.FieldTitle}
      textTransform="uppercase"
    />
  );
}
