import { Tooltip } from "antd";
import { FlexBox } from "@ui/components/layout/FlexBox";
import { Body1 } from "@ui/components/text/Body1";
import { ColorClass } from "@ui/types/enums/ColorClass";
import { ColorValue } from "@ui/types/enums/ColorValue";

type Props = {
  children: JSX.Element;
  tooltipBackgroundColor?: ColorValue;
  tooltipContent: JSX.Element | string;
};

export function WrapWithTooltip({
  children,
  tooltipBackgroundColor = ColorValue.WebsiteBackground,
  tooltipContent,
}: Props) {
  const tooltipContentElem =
    typeof tooltipContent === "string" ? (
      <Body1 colorClass={ColorClass.Primary}>{tooltipContent}</Body1>
    ) : (
      tooltipContent
    );

  return (
    <Tooltip color={tooltipBackgroundColor} title={tooltipContentElem}>
      {/* The extra div is needed due to a bug in the component.
      See https://github.com/ant-design/ant-design/issues/15909 */}
      {children.type === "div" ? (
        children
      ) : (
        <FlexBox alignItems="center">{children}</FlexBox>
      )}
    </Tooltip>
  );
}
