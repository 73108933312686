import { FontClass } from "@ui/types/enums/FontClass";
import commonStyles from "@ui/components/inputs/text/css/InputCommon.module.css";
import { joinClasses } from "@ui/utils/joinClasses";
import { stringNumBytes } from "@ui/utils/string/stringNumBytes";
import { CSSProperties } from "react";
import { InputWithLabels } from "@ui/components/inputs/InputWithLabels";

type Props = {
  className?: string;
  label?: JSX.Element | string;
  maxLength?: number;
  onChange: (val: string) => void;
  placeholder?: string;
  resize?: CSSProperties["resize"];
  rows?: number;
  showMaxLengthHint?: boolean;
  subLabel?: JSX.Element | string;
  value: string;
};

export function TextArea({
  className,
  label,
  maxLength,
  onChange,
  placeholder,
  resize = "vertical",
  rows = 2,
  showMaxLengthHint = true,
  subLabel,
  value,
}: Props): JSX.Element {
  const hint =
    maxLength != null && showMaxLengthHint
      ? `${maxLength - stringNumBytes(value)} characters left`
      : undefined;

  return (
    <InputWithLabels hint={hint} label={label} subLabel={subLabel}>
      <div className={commonStyles.textAreaContainer}>
        <textarea
          className={joinClasses(
            commonStyles.textArea,
            className,
            FontClass.Body1
          )}
          maxLength={maxLength}
          onChange={(e) => {
            const val = e.target.value;
            if (maxLength != null && stringNumBytes(val) > maxLength) {
              return;
            }

            onChange(val);
          }}
          placeholder={placeholder}
          rows={rows}
          style={{ resize }}
          value={value}
        />
      </div>
    </InputWithLabels>
  );
}
