/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

export enum ColorValue {
  BackgroundOverlay = "var(--color-backgroundOverlay)",
  Error = "var(--color-error)",
  ErrorDisabled = "var(--color-errorDisabled)",
  ErrorHover = "var(--color-errorHover)",
  Facebook = "var(--color-facebook)",
  Ghost = "var(--color-ghost)",
  Google = "var(--color-google)",
  Hard = "var(--color-hard)",
  HardHover = "var(--color-hardHover)",
  Highlight = "var(--color-highlight)",
  HighlightDisabled = "var(--color-highlightDisabled)",
  HighlightHover = "var(--color-highlightHover)",
  LightPurple = "var(--color-lightPurple)",
  LightPurple2 = "var(--color-lightPurple2)",
  LightPurpleHover = "var(--color-lightPurpleHover)",
  Mauve = "var(--color-mauve)",
  MauveHover = "var(--color-mauveHover)",
  MenuPopup = "var(--color-menuPopup)",
  NotReviewed = "var(--color-notReviewed)",
  Primary = "var(--color-primary)",
  ProgressBar = "var(--color-progressBar)",
  SearchHighlight = "var(--color-searchHighlight)",
  Secondary = "var(--color-secondary)",
  Separator = "var(--color-separator)",
  SeparatorDisabled = "var(--color-separatorDisabled)",
  SeparatorHover = "var(--color-separatorHover)",
  SeparatorWithShadow = "var(--color-separatorWithShadow)",
  Success = "var(--color-success)",
  SuccessDisabled = "var(--color-successDisabled)",
  SuccessHover = "var(--color-successHover)",
  TagBackground = "var(--color-tagBackground)",
  Tertiary = "var(--color-tertiary)",
  WebsiteBackground = "var(--color-websiteBackground)",
  White = "var(--color-white)",
}
