/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

export enum ColorVariableDarkModeName {
  BackgroundOverlay = "--color-backgroundOverlay-darkMode",
  Error = "--color-error-darkMode",
  ErrorDisabled = "--color-errorDisabled",
  ErrorHover = "--color-errorHover-darkMode",
  Facebook = "--color-facebook-darkMode",
  Ghost = "--color-ghost-darkMode",
  Google = "--color-google-darkMode",
  Hard = "--color-hard-darkMode",
  HardHover = "--color-hardHover-darkMode",
  Highlight = "--color-highlight-darkMode",
  HighlightDisabled = "--color-highlightDisabled",
  HighlightHover = "--color-highlightHover-darkMode",
  LightPurple = "--color-lightPurple-darkMode",
  LightPurple2 = "--color-lightPurple2-darkMode",
  LightPurpleHover = "--color-lightPurpleHover",
  Mauve = "--color-mauve-darkMode",
  MauveHover = "--color-mauveHover-darkMode",
  MenuPopup = "--color-menuPopup-darkMode",
  NotReviewed = "--color-notReviewed-darkMode",
  Primary = "--color-primary-darkMode",
  ProgressBar = "--color-progressBar-darkMode",
  SearchHighlight = "--color-searchHighlight-darkMode",
  Secondary = "--color-secondary-darkMode",
  Separator = "--color-separator-darkMode",
  SeparatorDisabled = "--color-separatorDisabled",
  SeparatorHover = "--color-separatorHover-darkMode",
  SeparatorWithShadow = "--color-separatorWithShadow-darkMode",
  Success = "--color-success-darkMode",
  SuccessDisabled = "--color-successDisabled",
  SuccessHover = "--color-successHover-darkMode",
  TagBackground = "--color-tagBackground-darkMode",
  Tertiary = "--color-tertiary-darkMode",
  WebsiteBackground = "--color-websiteBackground-darkMode",
  White = "--color-white-darkMode",
}
