import { COLOR_VARIABLE_NAME_TO_COLOR_VARIABLE_DARK_MODE_NAME } from "@ui/constants/colors/ColorVariableNameToColorVariableDarkModeName";
import { COLOR_VARIABLE_NAME_TO_COLOR_VARIABLE_LIGHT_MODE_NAME } from "@ui/constants/colors/ColorVariableNameToColorVariableLightModeName";
import { assertUnreachable } from "shared";
import { ColorMode } from "@ui/types/enums/ColorMode";
import { ColorVariableName } from "@ui/types/enums/ColorVariableName";

function getVariableName(
  colorMode: ColorMode,
  colorVariableName: string
): string {
  switch (colorMode) {
    case ColorMode.DarkMode:
      return COLOR_VARIABLE_NAME_TO_COLOR_VARIABLE_DARK_MODE_NAME[
        colorVariableName as ColorVariableName
      ];
    case ColorMode.LightMode:
      return COLOR_VARIABLE_NAME_TO_COLOR_VARIABLE_LIGHT_MODE_NAME[
        colorVariableName as ColorVariableName
      ];
    default:
      return assertUnreachable(colorMode);
  }
}

export function switchToColorMode(colorMode: ColorMode) {
  const { documentElement } = document;
  Object.values(ColorVariableName).forEach((colorVariableName) => {
    documentElement.style.setProperty(
      colorVariableName,
      getComputedStyle(documentElement).getPropertyValue(
        getVariableName(colorMode, colorVariableName)
      )
    );
  });
}
