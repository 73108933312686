import { ElementId } from "@ui/types/enums/ElementId";
import containerStyles from "@ui/components/containers/css/ResponsiveContainer.module.css";
import { joinClasses } from "@ui/utils/joinClasses";
import { CSSProperties } from "react";

type Props = {
  children: any;
  className?: string;
  height?: string;
  id?: ElementId;
  maxWidthDisabled?: boolean;
  style?: CSSProperties;
};

export function ResponsiveContainer({
  children,
  className,
  height,
  id,
  maxWidthDisabled = false,
  style,
}: Props): JSX.Element {
  const styleToUse = {
    ...style,
    height,
    maxWidth: maxWidthDisabled ? "none" : undefined,
  };

  return (
    <div
      className={joinClasses(containerStyles.container, className)}
      id={id}
      style={styleToUse}
    >
      {children}
    </div>
  );
}
