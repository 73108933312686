import { ColorValue } from "@ui/types/enums/ColorValue";

type Props = {
  colorValue: ColorValue;
};

export function SortColumnIcon({ colorValue }: Props): JSX.Element {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.09961 5.2998L6.39939 2.00003L9.69917 5.2998"
        stroke={colorValue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.69917 11.499L6.39939 14.7988L3.09961 11.499"
        stroke={colorValue}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
