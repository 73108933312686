import { ColorValue } from "@ui/types/enums/ColorValue";
import { CrossIcon } from "@ui/components/icons/CrossIcon";
import { PlainButton } from "@ui/components/buttons/PlainButton";
import { emptyFunction } from "shared";

type Props = {
  className?: string;
  colorValue: ColorValue;
  isShown?: boolean;
  onClick?: () => void;
};

export function CloseButton({
  className,
  colorValue,
  isShown = true,
  onClick = emptyFunction,
}: Props): JSX.Element {
  return (
    <PlainButton
      className={className}
      onClick={onClick}
      style={{ fontSize: 0, visibility: isShown ? undefined : "hidden" }}
      type="button"
    >
      <CrossIcon colorValue={colorValue} size={24} />
    </PlainButton>
  );
}
