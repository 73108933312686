import { TextButton } from "@ui/components/buttons/TextButton";
import { FlexBox } from "@ui/components/layout/FlexBox";
import { ColorClass } from "@ui/types/enums/ColorClass";
import { FontClass } from "@ui/types/enums/FontClass";
import styles from "./css/PopoverMenu.module.css";

type MenuItem = {
  label: string;
  // To be used with a NextJS Link. Use instead of next/router b/c this handles preloading in a nicer way.
  linkPath?: string;
  onClick: () => void;
};

type Props = {
  items: Array<MenuItem>;
};

export function PopoverMenu({ items }: Props) {
  return (
    <FlexBox flexDirection="column">
      {items.map((item) => (
        <TextButton
          buttonThemeOrColorClass={ColorClass.Primary}
          className={styles.button}
          fontClass={FontClass.Body1}
          href={item.linkPath}
          key={item.label}
          onClick={item.onClick}
          textDecoration="none"
          type={item.linkPath != null ? "link_internal" : "button"}
        >
          {item.label}
        </TextButton>
      ))}
    </FlexBox>
  );
}
