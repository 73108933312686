/* eslint-disable react-hooks/exhaustive-deps */
import { Context, createContext, useEffect, useMemo, useState } from "react";
import { ColorMode } from "@ui/types/enums/ColorMode";
import { emptyFunction } from "shared";
import { getLocalStorage } from "@ui/utils/local-storage/getLocalStorage";
import { LocalStorageKey } from "@ui/types/enums/LocalStorageKey";
import { setLocalStorage } from "@ui/utils/local-storage/setLocalStorage";
import { switchToColorMode } from "@ui/utils/colors/switchToColorMode";

export type ColorModeContextData = {
  colorMode: ColorMode;
  isDarkMode: boolean;
  isLightMode: boolean;
  setColorMode: (val: ColorMode) => void;
};

export const ColorModeContext: Context<ColorModeContextData> =
  createContext<ColorModeContextData>({
    colorMode: ColorMode.LightMode,
    isDarkMode: false,
    isLightMode: false,
    setColorMode: emptyFunction,
  });

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function ColorModeContextProvider(props: ProviderProps): JSX.Element {
  const [colorMode, setColorMode] = useState(
    (getLocalStorage(LocalStorageKey.ColorMode) as ColorMode) ??
      ColorMode.LightMode
  );

  useEffect(() => {
    switchToColorMode(colorMode);
  }, []);

  const value = useMemo(
    () => ({
      colorMode,
      isDarkMode: colorMode === ColorMode.DarkMode,
      isLightMode: colorMode === ColorMode.LightMode,
      setColorMode: (val: ColorMode) => {
        setColorMode(val);
        setLocalStorage(LocalStorageKey.ColorMode, val);
        switchToColorMode(val);
      },
    }),
    [colorMode, setColorMode]
  );

  return (
    <ColorModeContext.Provider value={value}>
      {props.children}
    </ColorModeContext.Provider>
  );
}
