import {
  BOTTOM_TABS_HEIGHT,
  BottomTabs,
} from "@ui/components/bottom-tabs/BottomTabs";
import { useIsBottomTabsWidth } from "@ui/hooks/dimensions/useIsBottomTabsWidth";

type Props = {
  children: any;
  hideBottomTabs?: boolean;
};

export function PageWithBottomTabs({
  children,
  hideBottomTabs = false,
}: Props): JSX.Element {
  const isBottomTabsWidth = useIsBottomTabsWidth();

  if (!isBottomTabsWidth || hideBottomTabs) {
    return children;
  }

  return (
    <>
      <div style={{ paddingBottom: BOTTOM_TABS_HEIGHT }}>{children}</div>
      <BottomTabs />
    </>
  );
}
