import { iconSize } from "@ui/utils/iconSize";
import { joinClasses } from "@ui/utils/joinClasses";
import { emptyFunction } from "shared";
import styles from "./css/CloseButtonWithHover.module.css";

type Icon = "cross-20-ghost" | "cross-24-secondary" | "cross-32-secondary";

function getIconStyle(icon: Icon) {
  switch (icon) {
    case "cross-20-ghost":
      return styles.closeButton20Ghost;
    case "cross-24-secondary":
      return styles.closeButton24Secondary;
    case "cross-32-secondary":
      return styles.closeButton32Secondary;
    default:
      throw new Error(`Unexpected icon ${icon}`);
  }
}

type Props = {
  hidden?: boolean;
  icon: Icon;
  onClick: () => void;
};

export function CloseButtonWithHover({ hidden, icon, onClick }: Props) {
  // Add a bit to the size to account for border radius.
  const buttonSize = iconSize(icon)! + 8;

  return (
    <button
      className={joinClasses(styles.closeButton, getIconStyle(icon))}
      onClick={onClick}
      onTouchStart={emptyFunction}
      style={{
        borderRadius: buttonSize / 2,
        height: buttonSize,
        visibility: hidden === true ? "hidden" : "visible",
        width: buttonSize,
      }}
      type="button"
    />
  );
}
