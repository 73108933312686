/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

import { ColorClass } from "@ui/types/enums/ColorClass";
import { ColorValue } from "@ui/types/enums/ColorValue";

export const COLOR_CLASS_TO_COLOR_VALUE: Record<ColorClass, ColorValue> = {
  [ColorClass.BackgroundOverlay]: ColorValue.BackgroundOverlay,
  [ColorClass.Error]: ColorValue.Error,
  [ColorClass.ErrorDisabled]: ColorValue.ErrorDisabled,
  [ColorClass.ErrorHover]: ColorValue.ErrorHover,
  [ColorClass.Facebook]: ColorValue.Facebook,
  [ColorClass.Ghost]: ColorValue.Ghost,
  [ColorClass.Google]: ColorValue.Google,
  [ColorClass.Hard]: ColorValue.Hard,
  [ColorClass.HardHover]: ColorValue.HardHover,
  [ColorClass.Highlight]: ColorValue.Highlight,
  [ColorClass.HighlightDisabled]: ColorValue.HighlightDisabled,
  [ColorClass.HighlightHover]: ColorValue.HighlightHover,
  [ColorClass.LightPurple2]: ColorValue.LightPurple2,
  [ColorClass.LightPurple]: ColorValue.LightPurple,
  [ColorClass.LightPurpleHover]: ColorValue.LightPurpleHover,
  [ColorClass.Mauve]: ColorValue.Mauve,
  [ColorClass.MauveHover]: ColorValue.MauveHover,
  [ColorClass.MenuPopup]: ColorValue.MenuPopup,
  [ColorClass.NotReviewed]: ColorValue.NotReviewed,
  [ColorClass.Primary]: ColorValue.Primary,
  [ColorClass.ProgressBar]: ColorValue.ProgressBar,
  [ColorClass.SearchHighlight]: ColorValue.SearchHighlight,
  [ColorClass.Secondary]: ColorValue.Secondary,
  [ColorClass.Separator]: ColorValue.Separator,
  [ColorClass.SeparatorDisabled]: ColorValue.SeparatorDisabled,
  [ColorClass.SeparatorHover]: ColorValue.SeparatorHover,
  [ColorClass.SeparatorWithShadow]: ColorValue.SeparatorWithShadow,
  [ColorClass.Success]: ColorValue.Success,
  [ColorClass.SuccessDisabled]: ColorValue.SuccessDisabled,
  [ColorClass.SuccessHover]: ColorValue.SuccessHover,
  [ColorClass.TagBackground]: ColorValue.TagBackground,
  [ColorClass.Tertiary]: ColorValue.Tertiary,
  [ColorClass.WebsiteBackground]: ColorValue.WebsiteBackground,
  [ColorClass.White]: ColorValue.White,
};
