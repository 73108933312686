import { CSSProperties } from "react";
import { ColorClass } from "@ui/types/enums/ColorClass";
import { FontClass } from "@ui/types/enums/FontClass";
import { joinClasses } from "@ui/utils/joinClasses";
import styles from "@ui/components/text/css/BodyText.module.css";
import { Maybe } from "shared";

export type BodyTextProps = {
  children:
    | number
    | string
    | JSX.Element
    | Array<JSX.Element | string | number | boolean | null>
    | null;
  className?: string;
  colorClass: Maybe<ColorClass>;
  display?: CSSProperties["display"];
  fontClass: FontClass | "inherit";
  style?: CSSProperties;
  textAlign?: CSSProperties["textAlign"];
  textTransform?: CSSProperties["textTransform"];
  truncateLines?: number;
  whiteSpace?: CSSProperties["whiteSpace"];
};

export function BodyText({
  children,
  className,
  colorClass,
  display,
  fontClass,
  style,
  textAlign,
  textTransform,
  truncateLines,
  whiteSpace,
}: BodyTextProps): JSX.Element {
  const classNameJoined = joinClasses(
    fontClass,
    className,
    colorClass,
    styles.bodyText,
    truncateLines != null ? styles.truncate : undefined
  );

  const styleToUse = {
    ...(style ?? {}),
    ...(textAlign != null ? { textAlign } : {}),
    ...(textTransform != null ? { textTransform } : {}),
    ...(whiteSpace != null ? { whiteSpace } : {}),
    ...(display != null ? { display } : {}),
    ...(truncateLines != null
      ? { WebkitLineClamp: truncateLines, lineClamp: truncateLines }
      : {}),
  };

  return (
    <div className={classNameJoined} style={styleToUse}>
      {children}
    </div>
  );
}
