import styles from "@ui/components/containers/css/HideIfEmpty.module.css";
import { forwardRef } from "react";
import { joinClasses } from "@ui/utils/joinClasses";

type Props = {
  children: any;
  className?: string;
};

/**
 * See https://stackoverflow.com/a/70806881 for an in-depth explanation of when this component comes in handy.
 */
export const HideIfEmpty = forwardRef<HTMLDivElement, Props>(
  function HideIfEmptyInner({ children, className }, ref) {
    return (
      <div className={joinClasses(styles.container, className)} ref={ref}>
        {children}
      </div>
    );
  }
);
