import { BottomTabButton } from "@ui/components/bottom-tabs/BottomTabButton";
import styles from "@ui/components/bottom-tabs/css/BottomTabs.module.css";
import { CrossIcon } from "@ui/components/icons/CrossIcon";
import { ColorValue } from "@ui/types/enums/ColorValue";

export const BOTTOM_TABS_HEIGHT = 92;

export function BottomTabs() {
  return (
    <div className={styles.tabs} style={{ height: BOTTOM_TABS_HEIGHT }}>
      <BottomTabButton
        href="/button1"
        icon={<CrossIcon colorValue={ColorValue.Secondary} size={24} />}
      >
        Button 1
      </BottomTabButton>
      <BottomTabButton
        href="/button2"
        icon={<CrossIcon colorValue={ColorValue.Secondary} size={24} />}
      >
        Button 2
      </BottomTabButton>
      <BottomTabButton
        href="/button3"
        icon={<CrossIcon colorValue={ColorValue.Secondary} size={24} />}
      >
        Button 3
      </BottomTabButton>
    </div>
  );
}
