import { StylesConfig } from "react-select";
import { ColorValue } from "@ui/types/enums/ColorValue";
import { FontVariableName } from "@ui/types/enums/FontVariableName";

const BORDER_RADIUS = 8;
const FONT_FAMILY = `var(${FontVariableName.WorkSans})`;
const WIDTH = "100%";

// Keep in sync with TimezoneSelect.module.css
export function getSelectCustomStyles({
  fontSize = 16,
  hasError = false,
  height = 54,
  showCursor = true,
}: {
  fontSize?: number;
  hasError?: boolean;
  height?: number;
  showCursor?: boolean;
}): StylesConfig<{ label: string; value: string }, false> {
  return {
    container: (provided) => ({
      ...provided,
      marginTop: 2,
    }),
    control: (provided) => ({
      ...provided,
      "&:hover": {
        border: `1px solid ${hasError ? ColorValue.Error : ColorValue.Ghost}`,
      },
      backgroundColor: ColorValue.White,
      border: `1px solid ${hasError ? ColorValue.Error : ColorValue.Tertiary}`,
      borderColor: hasError ? ColorValue.Error : ColorValue.Tertiary,
      borderRadius: BORDER_RADIUS,
      boxShadow: "none",
      fontFamily: FONT_FAMILY,
      fontSize,
      fontWeight: 400,
      height,
      paddingLeft: 4,
      width: WIDTH,
    }),
    input: (provided) => ({
      ...provided,
      // To disable blinking text input cursor https://stackoverflow.com/a/51504285
      ...(showCursor
        ? {
            color: ColorValue.Primary,
          }
        : {
            color: "transparent",
            textShadow: `0 0 0 ${ColorValue.Primary}`,
          }),
    }),
    loadingMessage: (provided) => ({
      ...provided,
      alignItems: "center",
      display: "flex",
      fontFamily: FONT_FAMILY,
      fontSize,
      fontWeight: 400,
      justifyContent: "center",
      minHeight: height,
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: BORDER_RADIUS,
      marginTop: 10,
      minHeight: height,
      // Needed because we're applying a border-radius
      // 1. To make border-radius apply over scrollbar
      // 2. To make option hover states not overflow the border radius
      overflow: "hidden",
      padding: 0,
      width: WIDTH,
      zIndex: 900,
    }),
    menuList: (provided) => ({
      ...provided,
      minHeight: height,
      paddingBottom: 0,
      paddingTop: 0,
    }),
    menuPortal: (provided) => ({
      ...provided,
      left: "inherit",
      top: "inherit",
      zIndex: 900,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      alignItems: "center",
      display: "flex",
      fontFamily: FONT_FAMILY,
      fontSize,
      fontWeight: 400,
      justifyContent: "center",
      minHeight: height,
    }),
    option: (provided, state) => ({
      ...provided,
      ...(state.isSelected
        ? {
            backgroundColor: ColorValue.LightPurple2,
            color: ColorValue.Primary,
          }
        : { backgroundColor: ColorValue.MenuPopup, color: ColorValue.Primary }),
      "&:hover": !state.isSelected
        ? {
            backgroundColor: ColorValue.Tertiary,
            color: ColorValue.Primary,
          }
        : {},
      alignItems: "center",
      display: "flex",
      fontFamily: FONT_FAMILY,
      fontSize,
      fontWeight: 400,
      height,
      lineHeight: "26px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: ColorValue.Ghost,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: ColorValue.Primary,
    }),
  };
}
