import { PlainButton } from "@ui/components/buttons/PlainButton";
import { range, assertUnreachable, FixedLengthArray, IntRange } from "shared";
import styles from "@ui/components/buttons/css/BigToggleButton.module.css";
import { BodyText } from "@ui/components/text/BodyText";
import { joinClasses } from "@ui/utils/joinClasses";
import { FontClass } from "@ui/types/enums/FontClass";
import { ColorClass } from "@ui/types/enums/ColorClass";

type ComponentSize = "normal" | "small";

function getToggleSizeClass(componentSize: ComponentSize) {
  switch (componentSize) {
    case "small":
      return {
        toggle: styles.toggleSmall,
        toggleHighlight: styles.toggleHighlightSmall,
      };
    case "normal":
      return {
        toggle: "",
        toggleHighlight: "",
      };
    default:
      return assertUnreachable(componentSize);
  }
}

type Props<T extends number> = {
  labels: FixedLengthArray<string | JSX.Element, T>;
  onToggle: (val: IntRange<0, T>) => void;
  position: IntRange<0, T>;
  size?: ComponentSize;
};

export function BigToggleButton<T extends number>({
  labels,
  onToggle,
  position,
  size = "normal",
}: Props<T>): JSX.Element {
  const numToggles = labels.length;
  const toggleButtons = range(numToggles).map((val) => (
    <PlainButton
      key={val}
      className={styles.toggleButton}
      onClick={() => onToggle(val as IntRange<0, T>)}
    >
      <BodyText
        colorClass={null}
        className={position === val ? styles.textWhite : ColorClass.Primary}
        fontClass={size ? FontClass.Body1Medium : FontClass.Body1}
      >
        {labels[val]}
      </BodyText>
    </PlainButton>
  ));

  const widthBuffer = 14;
  const left = `calc(${widthBuffer / 2}px * (2 * ${position} + 1) + ((${
    100 / numToggles
  }% - ${widthBuffer}px) * ${position}))`;
  const width = `calc(${100 / numToggles}% - ${widthBuffer}px)`;

  return (
    <div
      className={joinClasses(styles.toggle, getToggleSizeClass(size).toggle)}
      style={{ gridTemplateColumns: `repeat(${numToggles}, minmax(0, 1fr))` }}
    >
      <div
        className={joinClasses(
          styles.toggleHighlight,
          getToggleSizeClass(size).toggleHighlight
        )}
        style={{
          left,
          width,
        }}
      />
      {toggleButtons}
    </div>
  );
}
