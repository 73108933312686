import { ColorValue } from "@ui/types/enums/ColorValue";

type Props = {
  colorValue: ColorValue;
};

export function PersonIcon({ colorValue }: Props): JSX.Element {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 7.00004C8.84166 7.00004 10.3333 5.50837 10.3333 3.66671C10.3333 1.82504 8.84166 0.333374 7 0.333374C5.15833 0.333374 3.66667 1.82504 3.66667 3.66671C3.66667 5.50837 5.15833 7.00004 7 7.00004ZM7 8.66671C4.775 8.66671 0.333332 9.78337 0.333332 12V13.6667H13.6667V12C13.6667 9.78337 9.225 8.66671 7 8.66671Z"
        fill={colorValue}
      />
    </svg>
  );
}
