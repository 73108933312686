/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

import { ColorVariableLightModeName } from "@ui/types/enums/ColorVariableLightModeName";
import { ColorVariableName } from "@ui/types/enums/ColorVariableName";

export const COLOR_VARIABLE_NAME_TO_COLOR_VARIABLE_LIGHT_MODE_NAME: Record<
  ColorVariableName,
  ColorVariableLightModeName
> = {
  [ColorVariableName.BackgroundOverlay]:
    ColorVariableLightModeName.BackgroundOverlay,
  [ColorVariableName.Error]: ColorVariableLightModeName.Error,
  [ColorVariableName.ErrorDisabled]: ColorVariableLightModeName.ErrorDisabled,
  [ColorVariableName.ErrorHover]: ColorVariableLightModeName.ErrorHover,
  [ColorVariableName.Facebook]: ColorVariableLightModeName.Facebook,
  [ColorVariableName.Ghost]: ColorVariableLightModeName.Ghost,
  [ColorVariableName.Google]: ColorVariableLightModeName.Google,
  [ColorVariableName.Hard]: ColorVariableLightModeName.Hard,
  [ColorVariableName.HardHover]: ColorVariableLightModeName.HardHover,
  [ColorVariableName.Highlight]: ColorVariableLightModeName.Highlight,
  [ColorVariableName.HighlightDisabled]:
    ColorVariableLightModeName.HighlightDisabled,
  [ColorVariableName.HighlightHover]: ColorVariableLightModeName.HighlightHover,
  [ColorVariableName.LightPurple2]: ColorVariableLightModeName.LightPurple2,
  [ColorVariableName.LightPurple]: ColorVariableLightModeName.LightPurple,
  [ColorVariableName.LightPurpleHover]:
    ColorVariableLightModeName.LightPurpleHover,
  [ColorVariableName.Mauve]: ColorVariableLightModeName.Mauve,
  [ColorVariableName.MauveHover]: ColorVariableLightModeName.MauveHover,
  [ColorVariableName.MenuPopup]: ColorVariableLightModeName.MenuPopup,
  [ColorVariableName.NotReviewed]: ColorVariableLightModeName.NotReviewed,
  [ColorVariableName.Primary]: ColorVariableLightModeName.Primary,
  [ColorVariableName.ProgressBar]: ColorVariableLightModeName.ProgressBar,
  [ColorVariableName.SearchHighlight]:
    ColorVariableLightModeName.SearchHighlight,
  [ColorVariableName.Secondary]: ColorVariableLightModeName.Secondary,
  [ColorVariableName.Separator]: ColorVariableLightModeName.Separator,
  [ColorVariableName.SeparatorDisabled]:
    ColorVariableLightModeName.SeparatorDisabled,
  [ColorVariableName.SeparatorHover]: ColorVariableLightModeName.SeparatorHover,
  [ColorVariableName.SeparatorWithShadow]:
    ColorVariableLightModeName.SeparatorWithShadow,
  [ColorVariableName.Success]: ColorVariableLightModeName.Success,
  [ColorVariableName.SuccessDisabled]:
    ColorVariableLightModeName.SuccessDisabled,
  [ColorVariableName.SuccessHover]: ColorVariableLightModeName.SuccessHover,
  [ColorVariableName.TagBackground]: ColorVariableLightModeName.TagBackground,
  [ColorVariableName.Tertiary]: ColorVariableLightModeName.Tertiary,
  [ColorVariableName.WebsiteBackground]:
    ColorVariableLightModeName.WebsiteBackground,
  [ColorVariableName.White]: ColorVariableLightModeName.White,
};
