/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

export enum ColorVariableName {
  BackgroundOverlay = "--color-backgroundOverlay",
  Error = "--color-error",
  ErrorDisabled = "--color-errorDisabled",
  ErrorHover = "--color-errorHover",
  Facebook = "--color-facebook",
  Ghost = "--color-ghost",
  Google = "--color-google",
  Hard = "--color-hard",
  HardHover = "--color-hardHover",
  Highlight = "--color-highlight",
  HighlightDisabled = "--color-highlightDisabled",
  HighlightHover = "--color-highlightHover",
  LightPurple = "--color-lightPurple",
  LightPurple2 = "--color-lightPurple2",
  LightPurpleHover = "--color-lightPurpleHover",
  Mauve = "--color-mauve",
  MauveHover = "--color-mauveHover",
  MenuPopup = "--color-menuPopup",
  NotReviewed = "--color-notReviewed",
  Primary = "--color-primary",
  ProgressBar = "--color-progressBar",
  SearchHighlight = "--color-searchHighlight",
  Secondary = "--color-secondary",
  Separator = "--color-separator",
  SeparatorDisabled = "--color-separatorDisabled",
  SeparatorHover = "--color-separatorHover",
  SeparatorWithShadow = "--color-separatorWithShadow",
  Success = "--color-success",
  SuccessDisabled = "--color-successDisabled",
  SuccessHover = "--color-successHover",
  TagBackground = "--color-tagBackground",
  Tertiary = "--color-tertiary",
  WebsiteBackground = "--color-websiteBackground",
  White = "--color-white",
}
