import { PlainButton } from "@ui/components/buttons/PlainButton";
import styles from "@ui/components/buttons/css/RadioButton.module.css";

type Props = {
  isActive: boolean;
  onClick?: () => void;
};

export function RadioButton({ isActive, onClick }: Props): JSX.Element {
  const className = isActive ? styles.active : styles.inactive;

  if (onClick == null) {
    return <div className={className} />;
  }

  return <PlainButton className={className} onClick={onClick} />;
}
