import { ColorValue } from "@ui/types/enums/ColorValue";
import { GlobalClass } from "@ui/types/enums/GlobalClass";
import { HamburgerIcon } from "@ui/components/icons/HamburgerIcon";
import { PlainButton } from "@ui/components/buttons/PlainButton";

type Props = {
  colorValue: ColorValue;
  isShown?: boolean;
  onClick?: () => void;
};

export function HamburgerButton({
  colorValue,
  isShown = true,
  onClick,
}: Props): JSX.Element {
  return (
    <PlainButton
      className={GlobalClass.HideText}
      onClick={onClick}
      type="button"
    >
      <div style={isShown ? {} : { visibility: "hidden" }}>
        <HamburgerIcon colorValue={colorValue} />
      </div>
    </PlainButton>
  );
}
