import {
  ColorModeContext,
  ColorModeContextData,
} from "@ui/context/ColorModeContext";

import { useContext } from "react";

export function useColorModeContext(): ColorModeContextData {
  return useContext(ColorModeContext);
}
