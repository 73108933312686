import {
  DropEvent,
  ErrorCode,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import {
  emptyFunction,
  Maybe,
  MaybeUndef,
  PartialRecord,
  pluralize,
} from "shared";
import { joinClasses } from "@ui/utils/joinClasses";
import styles from "@ui/components/inputs/css/Dropzone.module.css";
import { useState } from "react";
import { Dimensions } from "@ui/types/Dimensions";
import { bytesToHumanReadableSize } from "@ui/utils/bytes/bytesToHumanReadableSize";
import { message } from "@ui/components/toast/message";
import { megabytesToBytes } from "@ui/utils/bytes/megabytesToBytes";
import { MediaType } from "@ui/types/enums/MediaType";
import { HUMAN_READABLE_MEDIA_TYPE } from "@ui/constants/HumanReadableMediaType";
import { getImageDimensions } from "@ui/utils/assets/getImageDimensions";
import { UNEXPECTED_ERROR_MESSAGE } from "@ui/constants/UnexpectedErrorMessage";

export const DEFAULT_MAX_FILES = 10;
export type Accept = PartialRecord<MediaType, Array<string>>;

function getAcceptErrorMessage(accept: Accept): string {
  const humanReadableMediaTypes = Object.keys(accept).map(
    (mediaType) => HUMAN_READABLE_MEDIA_TYPE[mediaType as MediaType]
  );

  if (humanReadableMediaTypes.length === 1) {
    return `Invalid file type, only ${humanReadableMediaTypes[0]} ${
      humanReadableMediaTypes[0] ===
      HUMAN_READABLE_MEDIA_TYPE[MediaType.AnkiExports]
        ? "are"
        : "is"
    } allowed`;
  }

  const acceptHumanReadable = `${humanReadableMediaTypes
    .slice(0, -1)
    .join(", ")} and ${humanReadableMediaTypes.slice(-1)[0]}`;
  return `Invalid file type, only ${acceptHumanReadable} are allowed`;
}

function getOnDropRejectedErrorMessage(
  fileRejections: Array<FileRejection>,
  accept: Accept,
  maxFiles: number,
  maxSize: number
) {
  if (fileRejections.length === 0 || fileRejections[0].errors.length === 0) {
    return UNEXPECTED_ERROR_MESSAGE;
  }

  const firstRejection = fileRejections[0];
  const firstError = firstRejection.errors[0];
  switch (firstError.code) {
    case ErrorCode.FileInvalidType: {
      return getAcceptErrorMessage(accept);
    }
    case ErrorCode.FileTooLarge:
      return `File is too big, max size is ${bytesToHumanReadableSize(
        maxSize
      )}`;
    case ErrorCode.FileTooSmall:
      return "File is too small";
    case ErrorCode.TooManyFiles:
      return `Too many files, you can only upload ${maxFiles} ${pluralize(
        "file",
        maxFiles
      )}`;
    default:
      return UNEXPECTED_ERROR_MESSAGE;
  }
}

type Props = {
  Component?: (props: {
    acceptedFiles: Array<File>;
    imageDimensions?: MaybeUndef<Dimensions>;
  }) => Maybe<JSX.Element>;
  accept: Accept;
  children?: any;
  className?: string;
  disableHoverStyle?: boolean;
  disabled?: boolean;
  maxFiles?: number;
  maxSize?: number;
  onDropAccepted?: <T extends File>(files: Array<T>, event: DropEvent) => void;
};

export function Dropzone({
  Component,
  accept,
  children,
  className,
  disableHoverStyle = false,
  disabled = false,
  maxFiles = DEFAULT_MAX_FILES,
  maxSize = megabytesToBytes(100),
  onDropAccepted = emptyFunction,
}: Props): JSX.Element {
  const [dimensions, setDimensions] = useState<Maybe<Dimensions>>(null);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    // isDragAccept,
    isDragActive,
    // isDragReject,
  } = useDropzone({
    accept,
    disabled,
    maxFiles,
    maxSize,
    onDropAccepted: async (files, event) => {
      onDropAccepted(files, event);

      if (files.length === maxFiles) {
        const dataUri = URL.createObjectURL(files[0]);
        const imageDimensions = await getImageDimensions(dataUri);
        setDimensions(imageDimensions);
      }
    },
    onDropRejected: (fileRejections) => {
      message({
        content: getOnDropRejectedErrorMessage(
          fileRejections,
          accept,
          maxFiles,
          maxSize
        ),
        duration: 5,
        type: "error",
      });
    },
  });

  return (
    <div
      {...getRootProps({
        className: joinClasses(
          styles.dropzone,
          !disableHoverStyle ? styles.dropzoneHover : null,
          isDragActive && !disableHoverStyle ? styles.dragActive : null,
          // TODO: this behavior is broken... waiting for fix.
          // See https://github.com/react-dropzone/react-dropzone/issues/888 for more
          // isDragAccept ? styles.dragAccept : null,
          // isDragReject ? styles.dragReject : null,
          className
        ),
      })}
    >
      <input {...getInputProps()} />
      {Component != null && (
        <Component acceptedFiles={acceptedFiles} imageDimensions={dimensions} />
      )}
      {children}
      <div className={styles.overlayAccept} />
      <div className={styles.overlayReject} />
    </div>
  );
}
