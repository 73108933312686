import { Maybe, MaybeUndef } from "shared";

import { BackgroundOverlay } from "@ui/components/loading/BackgroundOverlay";
import { ColorClass } from "@ui/types/enums/ColorClass";
import { OutsideClickHideModal } from "@ui/components/modals/OutsideClickHideModal";
import ReactDOM from "react-dom";
import { joinClasses } from "@ui/utils/joinClasses";
import styles from "@ui/components/modals/css/Modal.module.css";
import { RefObject } from "react";
import { FontClass } from "@ui/types/enums/FontClass";
import { BottomDrawer } from "@ui/components/drawers/BottomDrawer";
import { ElementId } from "@ui/types/enums/ElementId";
import { useBreakpoints } from "@ui/hooks/dimensions/useBreakpoints";
import { useDisableBodyScroll } from "@ui/hooks/useDisableBodyScroll";
import { Body1 } from "@ui/components/text/Body1";
import { CloseButtonWithHover } from "@ui/components/buttons/CloseButtonWithHover";

type Props = {
  bottomDrawerHeight?: number;
  button?: JSX.Element;
  // Assumed to be 24 x 24. If this assumption no longer holds, need to refactor this component.
  buttonTopLeft?: JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  className?: string;
  description?: string | JSX.Element;
  disableResponsiveContainerBottomDrawer?: boolean;
  excludeRefs?: Array<RefObject<HTMLElement>>;
  footer?: MaybeUndef<JSX.Element>;
  hideWithVisibility?: boolean;
  isShown: boolean;
  modalId?: ElementId;
  onHide: () => void;
  title: string | JSX.Element;
  titleFontClass?: FontClass;
};

export function Modal({
  bottomDrawerHeight,
  button,
  buttonTopLeft,
  children,
  className,
  description,
  disableResponsiveContainerBottomDrawer = false,
  excludeRefs,
  footer,
  hideWithVisibility = false,
  isShown,
  modalId,
  onHide,
  title,
  titleFontClass = FontClass.Header2,
}: Props): Maybe<JSX.Element> {
  const { isWidth768Breakpoint } = useBreakpoints();
  // Disable outer scroll when modal is visible.
  useDisableBodyScroll(isShown);

  if (!isShown && !hideWithVisibility) {
    return null;
  }

  if (isWidth768Breakpoint) {
    return (
      <BottomDrawer
        bottomDrawerHeight={bottomDrawerHeight}
        button={button}
        buttonTopLeft={buttonTopLeft}
        description={description}
        disableResponsiveContainer={disableResponsiveContainerBottomDrawer}
        isShown={isShown}
        onHide={onHide}
        title={title}
      >
        {children}
      </BottomDrawer>
    );
  }

  return ReactDOM.createPortal(
    <div
      className={styles.container}
      style={{
        top: window.scrollY,
        visibility: !isShown && hideWithVisibility ? "hidden" : "visible",
      }}
    >
      {isShown && <BackgroundOverlay />}
      <div className={styles.containerInner}>
        <OutsideClickHideModal
          className={styles.outsideClick}
          hideModal={onHide}
          excludeRefs={excludeRefs}
          onBackgroundOverlayOnly
        >
          <div id={modalId} className={joinClasses(styles.modal, className)}>
            {typeof title === "string" ? (
              <div className={styles.titleAndCloseButton}>
                {buttonTopLeft ?? (
                  <CloseButtonWithHover
                    icon="cross-24-secondary"
                    onClick={onHide}
                  />
                )}
                <div
                  className={joinClasses(ColorClass.Primary, titleFontClass)}
                  style={{ textAlign: "center" }}
                >
                  {title}
                </div>
                {/* TODO: make more robust. For now, we assume buttonTopLeft is 24 x 24 */}
                <CloseButtonWithHover
                  icon="cross-24-secondary"
                  hidden
                  onClick={onHide}
                />
              </div>
            ) : (
              title
            )}
            <div className={styles.descriptionAndContent}>
              {description != null && (
                <div className={styles.description}>
                  {typeof description === "string" ? (
                    <Body1 colorClass={ColorClass.Primary} textAlign="center">
                      {description}
                    </Body1>
                  ) : (
                    description
                  )}
                </div>
              )}
              {children}
              {button != null && <div className={styles.button}>{button}</div>}
              {footer != null && <div className={styles.footer}>{footer}</div>}
            </div>
          </div>
        </OutsideClickHideModal>
      </div>
    </div>,
    document.body
  );
}
