/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

export enum ColorVariableLightModeName {
  BackgroundOverlay = "--color-backgroundOverlay-lightMode",
  Error = "--color-error-lightMode",
  ErrorDisabled = "--color-errorDisabled",
  ErrorHover = "--color-errorHover-lightMode",
  Facebook = "--color-facebook-lightMode",
  Ghost = "--color-ghost-lightMode",
  Google = "--color-google-lightMode",
  Hard = "--color-hard-lightMode",
  HardHover = "--color-hardHover-lightMode",
  Highlight = "--color-highlight-lightMode",
  HighlightDisabled = "--color-highlightDisabled",
  HighlightHover = "--color-highlightHover-lightMode",
  LightPurple = "--color-lightPurple-lightMode",
  LightPurple2 = "--color-lightPurple2-lightMode",
  LightPurpleHover = "--color-lightPurpleHover",
  Mauve = "--color-mauve-lightMode",
  MauveHover = "--color-mauveHover-lightMode",
  MenuPopup = "--color-menuPopup-lightMode",
  NotReviewed = "--color-notReviewed-lightMode",
  Primary = "--color-primary-lightMode",
  ProgressBar = "--color-progressBar-lightMode",
  SearchHighlight = "--color-searchHighlight-lightMode",
  Secondary = "--color-secondary-lightMode",
  Separator = "--color-separator-lightMode",
  SeparatorDisabled = "--color-separatorDisabled",
  SeparatorHover = "--color-separatorHover-lightMode",
  SeparatorWithShadow = "--color-separatorWithShadow-lightMode",
  Success = "--color-success-lightMode",
  SuccessDisabled = "--color-successDisabled",
  SuccessHover = "--color-successHover-lightMode",
  TagBackground = "--color-tagBackground-lightMode",
  Tertiary = "--color-tertiary-lightMode",
  WebsiteBackground = "--color-websiteBackground-lightMode",
  White = "--color-white-lightMode",
}
