/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

import { ColorVariableDarkModeName } from "@ui/types/enums/ColorVariableDarkModeName";
import { ColorVariableName } from "@ui/types/enums/ColorVariableName";

export const COLOR_VARIABLE_NAME_TO_COLOR_VARIABLE_DARK_MODE_NAME: Record<
  ColorVariableName,
  ColorVariableDarkModeName
> = {
  [ColorVariableName.BackgroundOverlay]:
    ColorVariableDarkModeName.BackgroundOverlay,
  [ColorVariableName.Error]: ColorVariableDarkModeName.Error,
  [ColorVariableName.ErrorDisabled]: ColorVariableDarkModeName.ErrorDisabled,
  [ColorVariableName.ErrorHover]: ColorVariableDarkModeName.ErrorHover,
  [ColorVariableName.Facebook]: ColorVariableDarkModeName.Facebook,
  [ColorVariableName.Ghost]: ColorVariableDarkModeName.Ghost,
  [ColorVariableName.Google]: ColorVariableDarkModeName.Google,
  [ColorVariableName.Hard]: ColorVariableDarkModeName.Hard,
  [ColorVariableName.HardHover]: ColorVariableDarkModeName.HardHover,
  [ColorVariableName.Highlight]: ColorVariableDarkModeName.Highlight,
  [ColorVariableName.HighlightDisabled]:
    ColorVariableDarkModeName.HighlightDisabled,
  [ColorVariableName.HighlightHover]: ColorVariableDarkModeName.HighlightHover,
  [ColorVariableName.LightPurple2]: ColorVariableDarkModeName.LightPurple2,
  [ColorVariableName.LightPurple]: ColorVariableDarkModeName.LightPurple,
  [ColorVariableName.LightPurpleHover]:
    ColorVariableDarkModeName.LightPurpleHover,
  [ColorVariableName.Mauve]: ColorVariableDarkModeName.Mauve,
  [ColorVariableName.MauveHover]: ColorVariableDarkModeName.MauveHover,
  [ColorVariableName.MenuPopup]: ColorVariableDarkModeName.MenuPopup,
  [ColorVariableName.NotReviewed]: ColorVariableDarkModeName.NotReviewed,
  [ColorVariableName.Primary]: ColorVariableDarkModeName.Primary,
  [ColorVariableName.ProgressBar]: ColorVariableDarkModeName.ProgressBar,
  [ColorVariableName.SearchHighlight]:
    ColorVariableDarkModeName.SearchHighlight,
  [ColorVariableName.Secondary]: ColorVariableDarkModeName.Secondary,
  [ColorVariableName.Separator]: ColorVariableDarkModeName.Separator,
  [ColorVariableName.SeparatorDisabled]:
    ColorVariableDarkModeName.SeparatorDisabled,
  [ColorVariableName.SeparatorHover]: ColorVariableDarkModeName.SeparatorHover,
  [ColorVariableName.SeparatorWithShadow]:
    ColorVariableDarkModeName.SeparatorWithShadow,
  [ColorVariableName.Success]: ColorVariableDarkModeName.Success,
  [ColorVariableName.SuccessDisabled]:
    ColorVariableDarkModeName.SuccessDisabled,
  [ColorVariableName.SuccessHover]: ColorVariableDarkModeName.SuccessHover,
  [ColorVariableName.TagBackground]: ColorVariableDarkModeName.TagBackground,
  [ColorVariableName.Tertiary]: ColorVariableDarkModeName.Tertiary,
  [ColorVariableName.WebsiteBackground]:
    ColorVariableDarkModeName.WebsiteBackground,
  [ColorVariableName.White]: ColorVariableDarkModeName.White,
};
